<template>
  <div class="auth-wrapper">
    <div class="row auth-inner">
      <div class="container">
        <div class="col-12">
          <b-img src="@/assets/images/logo.svg" />

          <b-form class="auth-login-form" @submit.prevent="login">
            <b-form-group>
              <label for="login-email">
                Email <span class="required">*</span>
              </label>
              <b-form-input
                id="login-email"
                v-model="userEmail"
                name="login-email"
                autocomplete="email"
              />
            </b-form-group>

            <b-form-group>
              <label for="login-password">
                Password <span class="required">*</span>
              </label>
              <b-form-input
                id="login-password"
                v-model="password"
                type="password"
                class="form-control-merge"
                name="login-password"
                autocomplete="current-password"
              />
            </b-form-group>

            <div class="login-actions">
              <b-button type="submit" variant="success" block>
                Log in
              </b-button>
              <router-link class="forgot-password" to="/forgot-password"
                >Forgot your password?</router-link
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BForm, BButton, BImg } from 'bootstrap-vue'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import { SavePassword } from 'capacitor-ios-autofill-save-password'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      password: '',
      userEmail: '',
    }
  },
  created() {
    this.$store.dispatch('setCourierVisibility', false)
    this.$store.dispatch('setCourierLogState', false)
    this.$store.dispatch('setCourierCar', null)
  },
  methods: {
    firebaseLogin(userData) {
      if (userData) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register()
          } else {
            // Show some error
          }
        })
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', token => {
          this.$Users.updateResourceByUrl({
            url: userData['@id'],
            body: {
              firebaseToken: token.value,
            },
          })
        })

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', error => {
          alert(`Error on registration: ${JSON.stringify(error)}`)
        })
      }
    },
    login() {
      if (Capacitor.getPlatform() === 'ios') {
        SavePassword.promptDialog({
            username: this.userEmail,
            password: this.password,
        })
      }
      this.$JWT
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then(response => {
          if (response.code !== 401) {
            this.$JWT.setToken(response.data.token)
            this.$JWT.setRefreshToken(response.data.token)
            this.$JWT.axiosIns
              .get(`${this.apiUrl}${response.data.user}`)
              .then(userResponse => {
                if (userResponse.data.role !== 'ROLE_COURIER') {
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    'You do not have a permission to log in',
                    '',
                    'danger',
                  )
                  return
                }
                const userData = userResponse.data
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$store.dispatch('setCourierVisibility', userData.onOff)
                this.$store.dispatch('setCourierLogState', true)

                this.$Cars
                  .getCollection({ params: { courier: userData['@id'] } })
                  .then(carResponse => {
                    if (carResponse.data['hydra:member'].length) {
                      // eslint-disable-next-line prefer-destructuring
                      this.$store.dispatch(
                        'setCourierCar',
                        carResponse.data['hydra:member'][0]['@id'],
                      )
                    }
                  })

                this.firebaseLogin(userData)
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$helper.showToast(
                      this.$toast,
                      Toast,
                      'Welcome',
                      '',
                      'success',
                    )
                  })
              })
          }
        })
    },
  },
}
</script>
